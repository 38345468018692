.breadCrumb {
  position: fixed;
  width: calc(100% - 210px);
  height: 61px;
  top: 53px;
  left: 210px;
  background-color: #f4f4f4;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 10px;
}

input {
  border-radius: 5px;
}

button {
  border-radius: 5px;
}

@media (max-width: 768px) {
  .breadCrumb {
    width: 100%;
    left: 0;
  }
}

.dashboardNumber {
  color: #000000;
  font-size: 25px;
  font-weight: 400;
}

.dashboardFirstTableTextPadding {
  padding-top: 15px;
}

.dashboardFirstTableNumberPadding {
  padding-top: 20px;
}

.dashboardText {
  color: #5b5b5b;
  font-size: 20px;
  font-weight: bold;
}

.dashboardAlertRatingTextPadding {
  padding-left: 25px;
}

.dashboardAlertRatingNumberPadding {
  padding-left: 80px;
}

.dashboardAlertContainer {
  display: flex;
  align-items: center;
  padding-top: 20px;
  padding-left: 35px;
  height: 80px;
}

.alertFilterButtonsContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 13px;
}

.alertFilterButtons {
  width: 140px;
  height: 29px;
  background-color: #ffffff;
  border-radius: 8px;
  color: #5b5b5b;
  font-size: 15px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: 15px;
  .buttonText {
    font-size: 15px;
    margin-right: 5px;
  }
}

.alertFilterButtonsOnClick {
  width: 140px;
  height: 29px;
  background-color: #5b5b5b;
  border-radius: 8px;
  color: #ffffff;
  font-size: 15px;
  display: flex;
  font-weight: 600;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: 15px;
  .buttonText {
    font-size: 15px;
    margin-right: 5px;
  }
}

.alertsHeader {
  .alertText {
    font-size: 25px;
    color: #000000;
    font-weight: bold;
    margin-left: 15px;
  }
}

.buttonEnable {
  width: 70px;
  border-radius: 5px;
  background-color: #1876d1;
  color: #ffffff;
  font-size: 13px;
  text-align: center;
  line-height: 30px;
  font-family: 'Segoe UI';
  cursor: pointer;
  &:hover {
    background-color: #5e5e5e;
  }
}

.linkStyle {
  cursor: pointer;
  color: #1876d1;
  text-decoration: underline;
  &:hover {
    color: #545454;
  }
}

.searchParameterContainer {
  display: flex;
  background-color: #d8d8d8;
  position: sticky;
  margin-top: 5px;
  align-items: center;
  height: 55px;
}

.investigationContent {
  padding-top: 5px;
  height: calc(100vh - 190px);
  overflow: auto;
  .personInfoTable {
    .MuiTableCell-root {
      font-size: 12px;
    }
    border-radius: 15px;
    margin-top: 10px;
    margin-bottom: 20px;
  }
}

.tableAndChartContainer {
  margin-top: 10px;
  padding-right: 10px;
  height: calc(100vh - 190px);
}

.accessTimesContent {
  background-color: #ffffff;
  width: 99%;
  height: calc(100vh - 240px);
  border-radius: 0 5px 5px 5px;
  box-sizing: border-box;
  padding: 10px;
}

.graphBody {
  height: 100%;
  width: 100%;
  border: 2px solid #7f7f7f;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.accessTimesTypeContainer .changeGraphButton {
  margin-left: 10px;
  font-weight: 400;
  text-align: center;
  line-height: 30px;
  position: relative;
  top: 5px;
  font-size: 12px;
}

.changeGraphButtonActive {
  background-color: #8b8b8b !important;
}

.graphBodyContainer {
  height: calc(100% - 20px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
}

.organizationInfoSettingOperationContainer {
  width: 97.6%;
  display: flex;
  justify-content: flex-end;
}

.organizationInfoSettingTextInputContainer {
  margin-top: 20px;
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  .textInput {
    width: 95%;
    font-size: large;
    margin: 35px 0 35px 0;

    // padding: 5px;
  }
}

.organizationInfoSettingTitle {
  font-size: 20px;
  font-weight: bold;
  color: #5b5b5b;
}

.dateFilter {
  height: 36px;
  width: 210px;
  border: 1px solid #cecece;
  margin-top: 10px;
  border-radius: 5px;
  line-height: 36px;
  padding-left: 10px;
  color: #5b5b5b;
  cursor: pointer;
  margin-right: 5px;
  &:hover {
    background-color: #f4f4f4;
  }
}

.App .longSelect {
  width: 164px;
  color: #5b5b5b;
}

.ruleSettingTitle {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 160px;
  .ruleSettingTitle {
    font-size: 20px;
    font-weight: bold;
    color: #5b5b5b;
  }

  .ruleCount {
    font-size: 20px;
    color: #000000;
    font-weight: bold;
  }
  .ruleTotal {
    font-size: 14px;
    color: #a2a2a2;
  }
}
.ruleList {
  margin-top: 10px;
  height: calc(100% - 75px);
}

.operateRuleListModal {
  min-width: 350px;
  min-height: 100px;
}

.ruleSettingDetailsTitle {
  font-size: 20px;
  font-weight: bold;
  color: #5b5b5b;
  padding: 20px 0 10px 10px;
}

.addProviderAndClientTitle {
  font-size: 15px;
  font-weight: bold;
  color: #5b5b5b;
  padding-left: 25px;
  margin-top: 15px;
}

.searchModalContainer {
  display: flex;
  background-color: #d8d8d8;
  position: sticky;
  top: 20px;
  align-items: center;
  height: 58px;
  margin-bottom: 12px;
}

.exceptionListDeleteButton {
  background-color: #1876d1;
  &:hover {
    background-color: #1d88f3;
  }
}

.breadCrumbStyle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 98%;
}

.accessChartSideBarContainer {
  position: fixed;
  top: 0;
  width: 300px;
  height: 100%;
  background-color: #f4f4f4;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  transition: right 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}

.accessChartSideBarContent {
  width: 280px;
  height: calc(100% - 100px);
  padding: 180px 20px 20px 20px;
}

.paperStyle {
  padding: 10px;
  margin: 15px;
  height: calc(100vh - 165px);
  border-radius: 5px;
  width: 100%;
}

