.online-help {
    line-height: 1.6;
    position: relative;
    min-height: calc(100vh - 130px);
  
    h1, h2, h3 {
      color: #333;
      margin-top: 20px;
    }
  
    h1 {
      border-bottom: 2px solid #ddd;
      padding-bottom: 10px;
    }
  
    p {
      margin: 10px 0;
    }
  
    ul {
      list-style-type: disc;
      padding-left: 20px;
  
      li {
        margin: 5px 0;
      }
    }
  
    img {
      max-width: 100%;
      height: auto;
      display: block;
      margin: 10px 0;
    }
  
    .note {
      background-color: #f9f9f9;
      border-left: 4px solid #ccc;
      padding: 10px;
      margin: 20px 0;
    }
  
    pre {
      background: #f5f5f5;
      padding: 10px;
      border-radius: 5px;
      overflow-x: auto;
    }
  
    code {
      background: #f5f5f5;
      padding: 2px 4px;
      border-radius: 3px;
    }

    .loading-spinner {
      position: absolute; 
      top: 50%; 
      left: 50%; 
      transform: translate(-50%, -50%); 
    }
  }
  