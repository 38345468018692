@font-face {
    font-family: 'Segoe UI';
    src: url('./assets/fonts/SegoeUI.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Segoe UI';
    src: url('./assets/fonts/SegoeUIBold.ttf') format('ttf');
    font-weight: bold;
    font-style: normal;
}


html, body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    background-color: #D8D8D8;
    font-family: 'Segoe UI';


}

::-webkit-scrollbar{
    width:6px;

}

::-webkit-scrollbar-track{
    background-color: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 5px;
    &:hover {
      background-color: #555;
    }

}

button {
  text-transform: capitalize !important;
}
